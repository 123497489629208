import * as React from 'react';
import { LayoutItem } from '@oneaudi/unified-web-components';
import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { PartnerContext } from '../PartnerProvider';
import { useMedia } from '../../lib/hooks';

export const PartnerBusinessCardFixedNarrow = (
  variantConfig: VariantConfig,
): React.ReactElement | false => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);

  const { ctaBarButtonConfig = [] } = variantConfig;
  const { socialMedia = [] } = variantConfig;
  const {
    displayCtaBar,
    displayOfficialName,
    displayEmail,
    displayOpeningHours,
    displayPhone,
    isGoogleMapLink,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showSocialMediaLinks,
    // eslint-disable-next-line
  } = variantConfig?.contactDetails || variantConfig;

  const isScreenWidthAtLeastM = useMedia(`only screen and (min-width: 768px)`);

  const addressAndContactMargin = isScreenWidthAtLeastM
    ? 'var(--spacing-relative-xl)'
    : 'var(--spacing-relative-3xl)';

  return (
    partner &&
    partner.partnerId !== '' && (
      <>
        {/* {showJsonLd || showJsonLdAutoDealer ? (
          <JsonLd
            data={partner}
            jsonLdType={showJsonLd ? 'LocalBusiness' : 'AutoDealer'}
            socialMedia={socialMedia}
          />
        ) : null} */}
        <PartnerBusinessCardHeader
          partner={partner}
          isNarrow
          displayOfficialName={displayOfficialName}
          showImage={showImage}
          showServiceList={showServiceList}
          isLinkedToPWS={isLinkedToPWS}
        />
        <LayoutItem margin={[addressAndContactMargin, 0, 0, 0]}>
          <AddressAndContact
            isNarrow
            isLinkedToPWS={isLinkedToPWS}
            displayEmail={displayEmail}
            displayPhone={displayPhone}
            isGoogleMapLink={isGoogleMapLink}
            showMoreInfoLink={showMoreInfoLink}
            displayCtaBar={displayCtaBar}
            ctaBarButtonConfig={ctaBarButtonConfig}
            showSocialMediaLinks={showSocialMediaLinks}
            socialMedia={socialMedia}
          />
        </LayoutItem>

        {displayOpeningHours && hasOpeningHours && (
          <LayoutItem margin={['var(--spacing-relative-2xl)', 0, 0, 0]}>
            <OpeningHours data={partner} isNarrow />
          </LayoutItem>
        )}
      </>
    )
  );
};
