(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@feature-hub/react"), require("react"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@feature-hub/react", "react", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@feature-hub/react"), require("react"), require("styled-components")) : factory(root["@feature-hub/react"], root["react"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__9544__, __WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__8694__) => {
return 