/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Headline, ProgressIndicator, Text } from '@oneaudi/unified-web-components';
import { useQuery } from '@apollo/client';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { queryString } from '../queryHook';
import { ConfigProps, PartnerBusinessCard } from './components/PartnerBusinessCard';
import { mapContent } from './components/headless/ContentInterfaces';
import FeatureServiceContext from './FeatureServiceContext';

import { Container } from './style';
import { pruneOpeningHours } from '../lib/use-partner-data';
import { messages } from '../i18n/messages';
import { toISO3CountryCodeDealerFormatter } from '../lib/2toIso3';

const StyledProgressIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface FeatureAppProps {
  config?: Config;
}

interface Config extends Omit<ConfigProps, 'partnerID'> {
  [x: string]: any;
  partnerID?: string;
  variantConfig?: any;
  showVariant?: any;
}

interface PBCProps {
  config?: Config;
}
// eslint-disable-next-line
const PBCWrapper: React.FC<PBCProps> = ({ config }: PBCProps) => {
  const [pbcConfig, setPbcConfig] = React.useState<ConfigProps>();

  React.useEffect(() => {
    if (config?.partnerID || config?.pbcPartnerID) {
      setPbcConfig({
        ...config,
        partnerID: (config?.partnerID as string) || (config?.pbcPartnerID as string),
      });
    } else {
      // eslint-disable-next-line
      console.error(
        'Neither a content id to fetch partnerID from content or a config with partnerID is specified! Component will not render!',
      );
    }
  }, [config]);

  React.useEffect(() => {
    if (config?.partnerID) {
      setPbcConfig({
        ...config,
        variantConfig: {
          ...config?.variantConfig,
        },
        partnerID: config?.partnerID,
      });
    } else {
      // eslint-disable-next-line
      console.error('"partnerID" is missing! Component will not render!');
    }
  }, [config]);

  const regExp = /[a-zA-Z]/g;
  const isPartnerIdComplete = regExp.test(pbcConfig?.partnerID as string);
  const partnerId = isPartnerIdComplete
    ? pbcConfig?.partnerID
    : toISO3CountryCodeDealerFormatter(config?.locale as string, pbcConfig?.partnerID as string);

  // eslint-disable-next-line
  const { loading, data } = useQuery(queryString, {
    variables: { partnerId },
    fetchPolicy: 'no-cache',
  });
  let dealerData: any;

  if (pbcConfig?.vtpDealer?.id) {
    dealerData = pbcConfig.vtpDealer;
    return <PartnerBusinessCard config={pbcConfig} data={dealerData} />;
  }

  if (loading && !pbcConfig?.vtpDealer?.id) {
    return (
      <StyledProgressIndicatorContainer>
        <ProgressIndicator variant="spinner" colorScheme="colored" type="infinite" size="lg" />
      </StyledProgressIndicatorContainer>
    );
  }

  if (data) {
    let { dealerById } = data;
    if (dealerById?.openingHours) {
      dealerById = {
        ...dealerById,
        openingHours: pruneOpeningHours(dealerById.openingHours),
      };
    }
    dealerData = dealerById;

    return <PartnerBusinessCard config={pbcConfig} data={dealerData} />;
  }

  if (!data && !pbcConfig?.vtpDealer?.id && !loading) {
    console.error('Partner data does not exist.');
    return (
      <div style={{ textAlign: 'left' }}>
        <Headline
          tag="div"
          variant={{ type: 'headline', order: '3', weight: 'normal' }}
          margin={[0, 0, 'var(--spacing-relative-2xs)', 0]}
        >
          {useI18n(messages.missingPartnerDataErrorHeader)}
        </Headline>
        <Text variant={{ type: 'copy', order: '1', style: 'normal' }}>
          {useI18n(messages.missingPartnerDataErrorMessage)}
        </Text>
      </div>
    );
  }

  return null;
};

// eslint-disable-next-line
const FeatureApp: React.FC<FeatureAppProps> = ({ config }: FeatureAppProps) => {
  const context = React.useContext(FeatureServiceContext);
  const { contentService } = context;

  const [rawContent, setRawContent] = React.useState<any>(contentService?.getContent());
  let content: any = null;

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener(
        'pbcCtaClicked',
        (e: any) => {
          console.log(e?.detail);
        },
        false,
      );
    }
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener(
          'pbcCtaClicked',
          (e) => {
            console.log(e);
          },
          false,
        );
      }
    };
  });

  React.useEffect(() => {
    const listener = () => {
      setRawContent(contentService?.getContent());
    };
    contentService?.onContentChange(listener);

    return () => {
      contentService?.removeOnContentChange(listener);
    };
  }, [contentService]);

  // eslint-disable-next-line
  content = mapContent(rawContent);

  const pbcConfig = {
    partnerID: config?.partnerID ? config?.partnerID : content?.partnerID,
    variantConfig: config?.variantConfig
      ? config?.variantConfig
      : content?.variantConfigHeadless || content?.variantConfig,
    showVariant: config?.showVariant
      ? config?.showVariant
      : content?.showVariant || content?.pbcShowVariant,
    locale: config?.locale && config?.locale,
    imprint: config?.imprint && config?.imprint,
    registrationNumber: config?.registrationNumber && config?.registrationNumber,
    isBevAgency: config?.isBevAgency,
    isBEVAgency: config?.isBEVAgency,
    isBEVAgencyDealer: config?.isBEVAgencyDealer,
    investorSharedVehicle: config?.investorSharedVehicle && config?.investorSharedVehicle,
    nationWideSelling: config?.nationWideSelling,
    dealers: config?.dealers && config?.dealers,
    vtpDealerResultsURL: config?.vtpDealerResultsURL,
    vtpDealer: config?.vehicleBasic && config?.vehicleBasic?.dealer,
    dynamicLabel: config?.dynamicLabel && config?.dynamicLabel,
  };

  const standAlonePBC = !config?.partnerID || !content?.pbcPartnerID;

  return (
    <div data-testid="PBC">
      <Container variant={pbcConfig?.showVariant} standAlonePBC={standAlonePBC}>
        <PBCWrapper config={pbcConfig} />
      </Container>
    </div>
  );
};

export default FeatureApp;
