import * as React from 'react';

import styled from 'styled-components';
import { LayoutItem, Text } from '@oneaudi/unified-web-components';
import { useI18n } from '@oneaudi/i18n-context';
import FeatureServiceContext from '../FeatureServiceContext';
import { messages } from '../../i18n/messages';
import { getLocalizedWeekdayName, getLocalizedTime } from '../../lib/dateUtils';
import { DepartmentOpeningHours } from './PartnerData';
import { getDepartmentOpeningStatus, OpeningState } from './OpeningHoursCalculator';
import { camelize } from '../../lib/utils';
import { useMedia } from '../../lib/hooks';

type StatusIndicatorProps = {
  color: 'yellow' | 'red' | 'green';
};

const COLOR_MAP = {
  yellow: '#ffaa00',
  red: '#eb0d3f',
  green: '#0da20d',
};

// eslint-disable-next-line react/jsx-props-no-spreading
const StatusIndicator = styled((props) => <Text tag="span" {...props} />)`
  border-radius: 9999px;
  width: 11px;
  height: 11px;
  line-height: 1;
  content: '';
  margin-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
  background-color: ${({ color }: StatusIndicatorProps) => COLOR_MAP[color]};
`;

const StyledLayoutItem = styled(LayoutItem)`
  text-align: left;
`;

export const OpeningHoursLabel = ({
  department,
  now,
  isNarrow,
}: {
  department: DepartmentOpeningHours;
  now: Date;
  isNarrow?: boolean;
}): JSX.Element => {
  const {
    localeService: { language },
  } = React.useContext(FeatureServiceContext);
  const openingStatus = getDepartmentOpeningStatus(department, now);
  let openStatusLabel: React.ReactElement;
  let openStatusIndicator;

  const willOpenAtDayNameString =
    openingStatus.next?.day && getLocalizedWeekdayName(openingStatus.next?.day, language);

  const willOpenAtTime =
    openingStatus.next?.openTime && getLocalizedTime(openingStatus.next?.openTime, language);

  const isViewportM = useMedia(`only screen and (max-width: 768px)`);
  const spaceEnd = isViewportM || isNarrow ? 0 : 'var(--spacing-relative-xl)';

  switch (openingStatus.status) {
    case OpeningState.Open:
      openStatusLabel = (
        <Text tag="span">
          {useI18n(messages.openingHoursLabelOpenUntil)}
          {` ${
            openingStatus.current?.closeTime &&
            getLocalizedTime(openingStatus.current?.closeTime, language)
          }`}
        </Text>
      );
      openStatusIndicator = (
        <StatusIndicator margin={[0, 'var(--spacing-relative-sm)', 0, 0]} color="green" />
      );
      break;
    case OpeningState.OpenClosingSoon:
      openStatusLabel = (
        <Text tag="span">
          {useI18n(messages.openingHoursLabelClosesSoon)}

          {` ${
            openingStatus.current?.closeTime &&
            getLocalizedTime(openingStatus.current?.closeTime, language)
          }`}
        </Text>
      );
      openStatusIndicator = (
        <StatusIndicator margin={[0, 'var(--spacing-relative-sm)', 0, 0]} color="yellow" />
      );
      break;
    case OpeningState.Closed:
      openStatusLabel = (
        <Text tag="span">
          {useI18n(messages.openingHoursLabelClosed)}

          {`, `}

          {useI18n(messages.openingHoursLabelWillOpenAt)}

          {` ${willOpenAtDayNameString} ${willOpenAtTime}`}
        </Text>
      );
      openStatusIndicator = (
        <StatusIndicator margin={[0, 'var(--spacing-relative-sm)', 0, 0]} color="red" />
      );
      break;
    default:
      openStatusIndicator = null;
      openStatusLabel = (
        <Text tag="span">{useI18n(messages.openingHoursLabelStatusIsUnknown)}</Text>
      );
      break;
  }
  return (
    <StyledLayoutItem margin={[0, 0, spaceEnd, 0]}>
      <Text
        tag="span"
        variant={{ type: 'copy', order: '1', style: 'bold' }}
        margin={[0, 0, 'var(--spacing-relative-2xs)', 0]}
        id={`openingHoursDepartment-${camelize(department.departmentName)}`}
      >
        {department.departmentName}
      </Text>
      <Text tag="p" id={`statusDepartment-${camelize(department.departmentName)}`}>
        {openStatusIndicator}
        {openStatusLabel}
      </Text>
    </StyledLayoutItem>
  );
};
