import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Headline } from '@oneaudi/unified-web-components';
import styled from 'styled-components';
import { HeadlineVariant } from '@oneaudi/unified-web-components/dist/cjs/components/headline';
import { messages } from '../../../i18n/messages';
import { isTranslationLoaded } from '../../../lib/isTranslationLoaded';
import { useMedia } from '../../../lib/hooks';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { ImprintModal } from '../ImprintModal';
import { ButtonAlignedLeft } from '../PartnerBusinessCardAddress';
import { useTrackingManager } from '../../useTrackingManager';
import { DealerPhone, VtpDealer } from '../PartnerBusinessCard';
import { createDirectionsLink } from '../PartnerLinks';

interface Props {
  vtpData: {
    imprint?: string;
    vtpDealerResultsURL?: string;
    vtpDealer: VtpDealer;
    locale: string;
  };
  pbcRef: React.RefObject<HTMLDialogElement>;
}

const CustomButton = styled(ButtonAlignedLeft)`
  margin: 0 0 16px 0;
`;

const BevAgencyVehicleContainer = styled.div`
  display: grid;
  .bev-agency-label {
    margin: 0 0 16px 0;
  }
`;

export const BevAgencyVehicle: React.FC<Props> = ({ vtpData, pbcRef }): React.ReactElement => {
  const { vtpDealer, locale } = vtpData;
  const { name, street, zipCode, city, phoneNumbers } = vtpDealer;

  const phone = phoneNumbers?.filter((dealerPhone: DealerPhone) => dealerPhone.type === 'phone')[0]
    .number;
  const dealerAddress = `${street}, ${zipCode} ${city}`;
  const dealerDirectionUrl = createDirectionsLink({ ...vtpDealer, locale, address: dealerAddress });

  const trackingManager = useTrackingManager();
  const isViewportXS = useMedia(`only screen and (max-width: 375px)`);

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  const nameVariant = (() => {
    if (showTitle) return { type: 'headline', order: '4', weight: 'normal' };
    return { type: 'headline', order: isViewportXS ? '3' : '2', weight: 'bold' };
  })() as HeadlineVariant;

  const nameAs = (() => {
    if (showTitle) return 'div';
    return isViewportXS ? 'h3' : 'h2';
  })();

  return (
    <BevAgencyVehicleContainer>
      <Headline
        tag={nameAs}
        variant={nameVariant}
        data-testid="bev-agency-label"
        className="bev-agency-label"
      >
        {name}
      </Headline>

      {phone && (
        <CustomButton
          variant="standalone"
          icon="voice-call-1"
          iconPosition="leading"
          size="md"
          href={`tel:${phone}`}
          data-testid="phone"
          onClick={() =>
            trackingManager.click('call', dealerDirectionUrl, 'link', 'Anrufen', name, '2', 'phone')
          }
        >
          {phone}
        </CustomButton>
      )}

      {vtpData?.imprint && (
        <>
          <CustomButton
            variant="standalone"
            icon="address-book"
            iconPosition="leading"
            size="md"
            onClick={() => {
              openCloseDialog(pbcRef.current as HTMLDialogElement);
            }}
            data-test-id="imprint-cta"
          >
            {useI18n(messages.imprintMessage)}
          </CustomButton>
          <ImprintModal imprintRef={pbcRef} imprintData={vtpData.imprint} />
        </>
      )}
    </BevAgencyVehicleContainer>
  );
};
