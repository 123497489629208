import { Partner } from '@oneaudi/audi-partner-utils/lib/types';
import { createDirectionsLink, createMyAudiLink } from './PartnerLinks';

export type PbcPartner = {
  partnerId: string;
  officialName: string;
  nonOfficialName: string;
  locationName?: string | null;
  address: string[];
  phone?: string | null;
  fax?: string;
  url?: string | null;
  email?: string | null;
  locationImageUrl: string;
  vCardUrl: string;
  myAudiUrl: string | undefined;
  directionsUrl: string;
  services: string[];
  additionalServices?: string[];
  openingHours?: PartnerOpeningHours | null;
  long?: number;
  lat?: number;
  nameEnhanced: string; // TODO: Remove and utilise names above
};

export type PbcDataPartner = {
  partnerId: string;
  officialName: string;
  nonOfficialName: string;
  locationName?: string | null;
  address: string[];
  phone?: string | null;
  fax?: string;
  url?: string | null;
  email?: string | null;
  vCardUrl: string;
  myAudiUrl: string | undefined;
  directionsUrl: string;
  services: string[];
  additionalServices?: string[];
  openingHours?: PartnerOpeningHours | null;
  long?: number;
  lat?: number;
  nameEnhanced: string;
  additionalData: {
    locationImage: string;
    displayName: string;
  };
};

export type PartnerOpeningHours = {
  openingHoursNote?: string;
  departments: DepartmentOpeningHours[];
};

export type DepartmentOpeningHours = {
  id?: string;
  departmentName: string;
  departmentOpeningHoursNote?: string;
  openingHours: DayOpeningHours[];
};

export type DayOpeningHours = {
  id: WeekDay;
  open: boolean;
  timeRanges?: TimeRange[];
};

export type TimeRange = {
  openTime?: string;
  closeTime?: string;
};

export type WeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createPartner = (data: Partner | undefined): PbcPartner | undefined => {
  if (!data) {
    return undefined;
  }

  let nameEnhanced: string = data?.name || data?.additionalData?.displayName || '';

  if (data?.additionalData && data?.additionalData?.locationName) {
    nameEnhanced += ` ${data?.additionalData.locationName}`;
  }

  const officialName = `${data?.additionalData?.displayName || data?.name || ''} ${
    data?.additionalData?.locationName || ''
  }`;
  const nonOfficialName = `${data?.name || ''} ${data?.additionalData?.locationName || ''}`;

  return {
    partnerId: data && data?.dealerId,
    officialName,
    nonOfficialName,
    locationName: data?.additionalData?.locationName,
    address: data?.address,
    phone: data?.phone,
    url: data?.additionalData?.additionalUrl || data.url,
    email: data && data.email,
    locationImageUrl: data?.additionalData?.locationImage || '',
    vCardUrl: data?.vCardUrl || '',
    myAudiUrl: createMyAudiLink(data),
    directionsUrl: createDirectionsLink(data),
    services: data?.services,
    additionalServices: data?.additionalData?.additionalServices || [],
    openingHours: data && (data.openingHours as PartnerOpeningHours),
    long: data?.longitude,
    lat: data?.latitude,
    nameEnhanced,
  };
};
