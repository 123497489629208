import * as React from 'react';
import { ButtonGroup, LayoutProps, TextButtonProps } from '@oneaudi/unified-web-components';
import { ClickHandler, CtaCallbackButton, CtaCallbackButtonProps } from './CtaCallbackButton';
import { CtaUrlButton, CtaUrlButtonProps } from './CtaUrlButton';

export type CtaBarButtonConfig = {
  label: string;
  variant: TextButtonProps['variant'];
  url?: string;
  clickHandler?: ClickHandler;
}[];

export type CtaBarProps = LayoutProps & {
  ctaBarButtonConfig: CtaBarButtonConfig;
  displayname: string;
};

export type CtaButtonProps = {
  displayname: string;
  label: string;
  position: string;
  variant: TextButtonProps['variant'];
};

export const CtaBar = ({ ctaBarButtonConfig, displayname }: CtaBarProps): React.ReactElement => {
  // eslint-disable-next-line
  const [ctaButtons, setCtaButtons] = React.useState<any>();

  React.useEffect(() => {
    const ctaBtns = ctaBarButtonConfig.map((buttonConfig, index) => {
      const { label, variant, url, clickHandler } = buttonConfig;
      const position = (index + 1).toString();

      if (url) {
        if (clickHandler && url) {
          // eslint-disable-next-line no-console
          console.debug(
            'CTA has both clickHandler and url configured. Url is prefered and will be used!',
            buttonConfig,
          );
        }

        const ctaUrlButtonProps: CtaUrlButtonProps = {
          displayname,
          label,
          variant,
          url,
          position,
        };

        return <CtaUrlButton key={position} {...ctaUrlButtonProps} />;
      }

      if (clickHandler) {
        const ctaCallbackButtonProps: CtaCallbackButtonProps = {
          displayname,
          label,
          variant,
          clickHandler,
          position,
        };

        return <CtaCallbackButton key={position} {...ctaCallbackButtonProps} />;
      }

      // eslint-disable-next-line no-console
      console.debug('CTA config is missing url or clickHandler!', buttonConfig);
      return undefined;
    });

    setCtaButtons(ctaBtns);
  }, [ctaBarButtonConfig]);

  if (ctaBarButtonConfig.length > 0 && ctaButtons) {
    return <ButtonGroup margin={['var(--spacing-relative-lg)', 0, 0, 0]}>{ctaButtons}</ButtonGroup>;
  }
  return <div />;
};
