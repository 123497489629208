import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { GfaLocaleServiceV1 } from '../app/feature-service-types';

export const useClient = (locale: GfaLocaleServiceV1) => {
  const client = new ApolloClient({
    ssrMode: true,
    link: createHttpLink({
      uri: 'https://dealer-graphql.apps.emea.vwapps.io/',
      headers: {
        clientId: 'dg552wqh5t',
        'Accept-Language': `${locale.language}-${locale.countryCode.toLocaleUpperCase()}`,
      },
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
    },
  });

  return client;
};
