/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * This is the entry point for Feature Hub App integration
 */
import * as React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { QueryCache } from 'react-query';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import I18nContextComponent from '@oneaudi/i18n-context';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import {
  AsyncSsrManager,
  ContentService,
  EnvConfigService,
  GfaLocaleServiceV1,
  Logger,
  PersonalizationService,
  SerializedStateManager,
  TrackingService,
  QueryCacheService,
} from './feature-service-types';
import { FeatureAppProvider } from './FeatureAppProvider';
import { ConfigProps } from './components/PartnerBusinessCard';
import FeatureApp from './FeatureApp';
import { i18nScope } from '../i18n/index';
import { queryString } from '../queryHook';
import { JsonLd } from './components/Json-ld';
import { PbcDataPartner } from './components/PartnerData';
import {
  EditorJsonContent,
  FalconContent,
  mapContent,
} from './components/headless/ContentInterfaces';
import { useClient } from '../lib/client';

export const name = 'fa-partner-business-card';
interface RequiredFeatureServiceDependencies extends FeatureServices {
  readonly 'audi:envConfigService'?: EnvConfigService;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'dbad:audi-i18n-service'?: I18NServiceV1;
}
interface OptionalFeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-tracking-service'?: TrackingService;
  readonly 'audi-personalization-service'?: PersonalizationService;
  readonly 's2:async-ssr-manager'?: AsyncSsrManager;
  readonly 's2:serialized-state-manager'?: SerializedStateManager;
  readonly 'audi-ddmc:query-cache-service'?: QueryCacheService;
  readonly 'audi-content-service'?: ContentService;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

export interface FeatureServiceDependencies
  extends RequiredFeatureServiceDependencies,
    OptionalFeatureServiceDependencies,
    FeatureServices {}

export interface Env {
  dealerSearchServiceEndpointUrl: string;
}

export interface FeatureAppAsyncState {
  env: Env;
}

export async function fetchEnv(envConfigService: EnvConfigService) {
  const configName = 'partner-business-card';
  const env = await envConfigService.getConfiguration<Env>(configName);

  if (!env) {
    throw new Error(`Error while fetching ${configName} environment configuration`);
  }

  return env;
}

/**
 * It holds a function to retrieve the State needed by the feature app when
 * it is rendered on the server
 * It holds the State object created from the serialized state on the client
 */
export type FeatureAppAsyncStateHolder =
  | (() => Promise<FeatureAppAsyncState>)
  | FeatureAppAsyncState
  | undefined;

let asyncStateHolder: any;
let data: PbcDataPartner;
let content: EditorJsonContent;
let jsonLdType: string;
let initialData: PbcDataPartner;
let socialMedia: any;
let showJsonLd: boolean;

const setConfig = (editorContent: EditorJsonContent) => {
  if (editorContent?.variantConfig) {
    jsonLdType = editorContent?.variantConfig?.contactDetails.showJsonLdAutoDealer
      ? 'AutoDealer'
      : 'LocalBusiness';
    socialMedia = editorContent?.variantConfig?.socialMedia;
    showJsonLd =
      editorContent?.variantConfig?.contactDetails.showJsonLd ||
      content?.variantConfig?.contactDetails.showJsonLdAutoDealer;
  } else {
    jsonLdType = editorContent?.variantConfigHeadless?.showJsonLdAutoDealer
      ? 'AutoDealer'
      : 'LocalBusiness';
    socialMedia = editorContent?.variantConfigHeadless?.socialMedia;
    showJsonLd =
      editorContent?.variantConfigHeadless?.showJsonLd ||
      editorContent?.variantConfigHeadless?.showJsonLdAutoDealer;
  }
};

export const getAsyncStateHolder = async (
  contentService: ContentService,
  config: any,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  content = mapContent((await contentService?.getContent()) as EditorJsonContent | FalconContent);
  const id = content.partnerID || config?.partnerID;
  const { data: queryData } = await client.query({
    query: queryString,
    variables: { partnerId: id },
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  data = queryData?.dealerById;
  setConfig(content);

  initialData = data;

  return { content, data };
};

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  // (!) Keep in sync with README and feature-service-types.ts!
  dependencies: {
    externals: {
      react: '^16.13.0||^17.0.2||^18.x.x',
      'styled-components': '*',
      '@feature-hub/react': '^3.x.x',
      '@oneaudi/unified-web-common': '^1.6.0',
      '@oneaudi/unified-web-components': '^1.7.2',
    },
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
  },

  // (!) Keep in sync with README and feature-service-types.ts!
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^2.1.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-ddmc:query-cache-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    config,
  }: FeatureAppEnvironment<FeatureServiceDependencies, ConfigProps>) => {
    // const envConfigService = featureServices['audi:envConfigService'] as EnvConfigService;
    const localeService = featureServices['gfa:locale-service'];

    const logger = featureServices['s2:logger'] || console;
    const i18nData = featureServices['dbad:audi-i18n-service'] as I18NServiceV1;
    const trackingService = featureServices['audi-tracking-service'];

    const contentService = featureServices['audi-content-service'];

    if (typeof window !== 'undefined' && trackingService) {
      trackingService.featureAppName = name;
    }

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];

    const client = useClient(localeService);

    const serializedStateManager = featureServices['s2:serialized-state-manager'];

    if (asyncSSRManager) {
      const waitForThese = async () => {
        asyncStateHolder = await getAsyncStateHolder(contentService!, config, client);
        serializedStateManager?.register(() => JSON.stringify({ content, data }));
      };
      const promiseWaitForThese = waitForThese();
      asyncSSRManager.scheduleRerender(promiseWaitForThese);
    } else {
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState);
        const { data: asyncData, content: asyncContent } = asyncStateHolder;
        setConfig(asyncContent as EditorJsonContent);
        initialData = asyncData;
      } else {
        asyncStateHolder = getAsyncStateHolder(contentService!, config, client)
          .then((initData) => {
            const { data: asyncData, content: asyncContent } = initData;
            initialData = asyncData;
            setConfig(asyncContent);
          })
          .catch((err) => console.error(err));
      }
    }

    const queryCacheService = featureServices['audi-ddmc:query-cache-service'] || {
      queryCache: new QueryCache(),
    };

    return {
      render() {
        return (
          <UniversalEditorProvider
            contentService={featureServices['audi-content-service']!}
            renderModeService={featureServices['audi-render-mode-service']}
          >
            <FeatureAppProvider
              localeService={localeService}
              logger={logger}
              trackingService={trackingService}
              contentService={contentService}
              queryCacheService={queryCacheService}
              // envConfigService={envConfigService}
            >
              <ApolloProvider client={client}>
                <I18nContextComponent
                  i18nData={i18nData}
                  language={localeService.language}
                  scopes={[i18nScope]}
                >
                  {asyncStateHolder?.content?.variantConfig?.contactDetails?.showJsonLd ||
                  asyncStateHolder?.content?.variantConfig?.contactDetails?.showJsonLdAutoDealer ||
                  asyncStateHolder?.content?.variantConfigHeadless?.showJsonLd ||
                  asyncStateHolder?.content?.variantConfigHeadless?.showJsonLdAutoDealer ||
                  showJsonLd ? (
                    <JsonLd jsonLdType={jsonLdType} data={initialData} socialMedia={socialMedia} />
                  ) : null}
                  <FeatureApp config={config} />
                </I18nContextComponent>
              </ApolloProvider>
            </FeatureAppProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

// eslint-disable-next-line import/no-default-export
export default featureAppDefinition;
