import * as React from 'react';
import { LayoutItem } from '@oneaudi/unified-web-components';
import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { PartnerContext } from '../PartnerProvider';

export const PartnerBusinessCardFullLong = (
  variantConfig: VariantConfig,
): React.ReactElement | null => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);
  const { ctaBarButtonConfig = [] } = variantConfig;
  const { socialMedia = [] } = variantConfig;
  const {
    displayCtaBar,
    displayOfficialName,
    displayEmail,
    displayOpeningHours,
    displayPhone,
    isGoogleMapLink,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showSocialMediaLinks,
    // eslint-disable-next-line
  } = variantConfig.contactDetails || variantConfig;

  return partner && partner.partnerId !== '' ? (
    <>
      {/* {showJsonLd || showJsonLdAutoDealer ? (
        <JsonLd
          data={partner}
          jsonLdType={showJsonLd ? 'LocalBusiness' : 'AutoDealer'}
          socialMedia={socialMedia}
        />
      ) : null} */}
      <PartnerBusinessCardHeader
        partner={partner}
        displayOfficialName={displayOfficialName}
        isLinkedToPWS={isLinkedToPWS}
        showImage={showImage}
        showServiceList={showServiceList}
      />
      <LayoutItem>
        <AddressAndContact
          displayCtaBar={displayCtaBar}
          ctaBarButtonConfig={ctaBarButtonConfig}
          displayEmail={displayEmail}
          displayPhone={displayPhone}
          isGoogleMapLink={isGoogleMapLink}
          isLinkedToPWS={isLinkedToPWS}
          showMoreInfoLink={showMoreInfoLink}
          showImage={showImage}
          showSocialMediaLinks={showSocialMediaLinks}
          socialMedia={socialMedia}
        />
      </LayoutItem>
      {displayOpeningHours && hasOpeningHours && (
        <LayoutItem margin={['var(--spacing-relative-2xl)', 0, 0, 0]}>
          <OpeningHours data={partner} isNarrow />
        </LayoutItem>
      )}
    </>
  ) : null;
};
