/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Headline, Layout, LayoutItem, TextLink } from '@oneaudi/unified-web-components';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { transformServices } from '../../lib/transformServices';
import { messages } from '../../i18n/messages';
import { useTrackingManager } from '../useTrackingManager';
import { useMedia } from '../../lib/hooks';
import { Image } from './Image';

const StyledImage = styled.figure`
  position: relative;
  margin: 0;
  /* create aspect ratio box with an aspect ratio of 16:9 */
  padding: 0 0 56.25% 0;
  overflow: hidden;

  > img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

const StyledHeadlineAsLink = styled.div`
  > a {
    color: #333333;
    text-decoration: none;
  }
`;

const StyledTextLink = styled(TextLink)`
  text-decoration: none;
`;

interface PartnerBusinessCardHeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partner: any;
  // eslint-disable-next-line react/require-default-props
  isNarrow?: boolean;
  displayOfficialName?: boolean;
  // eslint-disable-next-line react/require-default-props
  showImage?: boolean;
  // eslint-disable-next-line react/require-default-props
  showServiceList?: boolean;
  // eslint-disable-next-line react/require-default-props
  isLinkedToPWS?: boolean;
}

export const PartnerBusinessCardHeader = (
  props: PartnerBusinessCardHeaderProps,
): React.ReactElement => {
  const {
    partner,
    isNarrow,
    displayOfficialName = true,
    showImage = true,
    showServiceList = true,
    isLinkedToPWS = true,
  } = props;
  const trackingManager = useTrackingManager();

  const myAudiLinkLabel = useI18n(messages.myAudiLinkLabel);

  const partnerServiceLabels = new Map([
    ['sales', useI18n(messages.partnerServiceSales)],
    ['sales-r8', useI18n(messages.partnerServiceSalesR8)],
    ['service', useI18n(messages.partnerServiceService)],
    ['etron', useI18n(messages.partnerServiceEtron)],
    ['usedcars', useI18n(messages.partnerServiceUsedcars)],
  ]);
  const services: any = transformServices(
    partner.services,
    partner.additionalServices,
    partnerServiceLabels,
  );
  // // TODO Check imgWidth value
  const imgWidth = 328;

  const isViewportXS = useMedia(`only screen and (max-width: 375px)`);
  const isViewportM = useMedia(`only screen and (min-width: 768px) `);
  const isViewportL = useMedia(`only screen and (min-width: 1024px) `);

  const showColumnItemPicture = (showImage && isNarrow) || (showImage && !isViewportM && !isNarrow);
  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;
  const [partnerImgSrc] = partner.locationImageUrl.split('?');

  const partnerImage = (
    <StyledImage>
      <Image
        loadingAnimation="fade"
        src={`${partnerImgSrc}?preferwebp=true?width=${imgWidth}`}
        data-testid="image"
        alt={partner.officialName}
        sizes={[
          {
            condition: `(max-width: ${imgWidth}px)`,
            value: `${imgWidth}px`,
          },
          {
            condition: '(max-width: 639px)',
            value: '656px',
          },
          {
            condition: '(max-width: 1023px)',
            value: `${imgWidth}px`,
          },
          {
            value: '656px',
          },
        ]}
        srcSet={[
          {
            src: `${partnerImgSrc}?preferwebp=true?width=${imgWidth}`,
            desc: `${imgWidth}w`,
          },
          {
            src: `${partnerImgSrc}?preferwebp=true?width=656`,
            desc: '656w',
          },
        ]}
      />
    </StyledImage>
  );

  return (
    <Layout
      direction={{ xs: 'column', lg: 'row' }}
      wrap="nowrap"
      align="stretch"
      justify="space-between"
    >
      <LayoutItem align="stretch">
        <Headline
          tag={isViewportXS ? 'h3' : 'h2'}
          variant={{ type: 'headline', order: isViewportXS ? '3' : '2', weight: 'normal' }}
          data-testid="name"
        >
          {isLinkedToPWS ? (
            <StyledHeadlineAsLink>
              <a
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url,
                    'headline',
                    partnerName,
                    partnerName,
                    '1',
                    'pws link',
                  )
                }
                href={partner.url}
                target="_blank"
                rel="noreferrer"
              >
                {partnerName}
              </a>
            </StyledHeadlineAsLink>
          ) : (
            partnerName
          )}
        </Headline>
        {showServiceList ? (
          <Headline
            tag="div"
            variant={{ type: 'headline', order: '4', weight: 'normal' }}
            margin={['var(--spacing-relative-sm)', 0, 'var(--spacing-relative-xl)', 0]}
            data-testid="services"
          >
            {services}
          </Headline>
        ) : null}
        {partner.locationImageUrl && showColumnItemPicture && (
          <LayoutItem margin={[0, 0, 'var(--spacing-relative-2xl)', 0]}>
            {isLinkedToPWS ? (
              <a
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url,
                    'image',
                    '',
                    partnerName,
                    '1',
                    'pws link',
                  )
                }
                href={partner.url}
                target={isNarrow ? '_blank' : ''}
                rel="noreferrer"
              >
                {partnerImage}
              </a>
            ) : (
              partnerImage
            )}
          </LayoutItem>
        )}
      </LayoutItem>
      {false && (
        <LayoutItem
          margin={[
            isViewportL ? 'var(--spacing-relative-sm)' : 'var(--spacing-relative-2xl)',
            0,
            !isViewportL ? 'var(--spacing-relative-md)' : 0,
            isViewportL ? '52px' : undefined,
          ]}
        >
          <StyledTextLink
            variant="standalone"
            href={partner.myAudiUrl}
            icon="bookmark"
            iconPosition="leading"
            size="md"
            onClick={() =>
              trackingManager.click(
                'internal_link',
                partner.myAudiUrl,
                'link',
                myAudiLinkLabel,
                partnerName,
                '',
                'myaudi link',
              )
            }
            data-testid="myaudi"
          >
            {useI18n(messages.myAudiLinkLabel)}
          </StyledTextLink>
        </LayoutItem>
      )}
    </Layout>
  );
};
