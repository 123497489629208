import * as React from 'react';
import styled from 'styled-components';
import { Headline, Layout, LayoutItem, TextLink } from '@oneaudi/unified-web-components';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerContext } from '../PartnerProvider';
import { useTrackingManager } from '../useTrackingManager';
import { useMedia } from '../../lib/hooks';

interface PartnerBusinessCardAddressProps {
  variantConfig: VariantConfig;
}

const StyledHeadlineAsLink = styled.div`
  margin-bottom: 15px;
  > a {
    color: #333333;
    text-decoration: none;
  }
`;

const AddressContainer = styled(LayoutItem)``;

export const ButtonAlignedLeft = styled(TextLink)`
  margin-bottom: 15px;

  text-align: left;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  text-decoration: none;
`;

export const PartnerBusinessCardAddress: React.FC<PartnerBusinessCardAddressProps> = ({
  variantConfig,
}): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const trackingManager = useTrackingManager();

  // eslint-disable-next-line
  const { isLinkedToPWS, hasOuterSpacing, isGoogleMapLink } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;
  // eslint-disable-next-line
  let { displayOfficialName, displayEmail, displayPhone } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;

  displayOfficialName = (displayOfficialName && partner.officialName) as unknown as boolean;
  displayEmail = (displayEmail && partner.email) as unknown as boolean;
  displayPhone = (displayPhone && partner.phone) as unknown as boolean;
  // eslint-disable-next-line
  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;

  const isViewportXS = useMedia(`only screen and (max-width: 375px)`);

  const containerMargin = hasOuterSpacing ? 'var(--spacing-relative-md)' : 0;

  return (
    <AddressContainer margin={[containerMargin]}>
      {partner && partner.partnerId !== '' && (
        <>
          <Headline
            tag={isViewportXS ? 'h3' : 'h2'}
            variant={{ type: 'headline', order: isViewportXS ? '3' : '2', weight: 'bold' }}
            data-testid="name"
          >
            {isLinkedToPWS && partner.url ? (
              <StyledHeadlineAsLink>
                <a href={partner.url} target="_blank" rel="noreferrer">
                  {partnerName}
                </a>
              </StyledHeadlineAsLink>
            ) : (
              <StyledHeadlineAsLink>{partnerName}</StyledHeadlineAsLink>
            )}
          </Headline>
          <Layout direction="column">
            {isGoogleMapLink && (
              <ButtonAlignedLeft
                variant="standalone"
                icon="geofence"
                iconPosition="leading"
                size="md"
                href={partner.directionsUrl}
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.directionsUrl,
                    'link',
                    `${partner.address[0]} ${partner.address[1]}`,
                    partnerName,
                    '1',
                    'location',
                  )
                }
                data-testid="directions"
              >
                {partner.address[0]}
                <br />
                {partner.address[1]}
              </ButtonAlignedLeft>
            )}

            {displayPhone && (
              <ButtonAlignedLeft
                href={`tel:${partner.phone}`}
                data-testid="phone"
                variant="standalone"
                icon="voice-call-1"
                iconPosition="leading"
                size="md"
                onClick={() =>
                  trackingManager.click(
                    'call',
                    partner.directionsUrl,
                    'link',
                    'Anrufen',
                    partnerName,
                    '2',
                    'phone',
                  )
                }
              >
                {partner.phone}
              </ButtonAlignedLeft>
            )}
            {partner.email && displayEmail && (
              <ButtonAlignedLeft
                href={`mailto:${partner.email}`}
                variant="standalone"
                icon="direct-mail"
                iconPosition="leading"
                size="md"
                onClick={() =>
                  trackingManager.click(
                    'email',
                    partner.directionsUrl,
                    'link',
                    partner.email || '',
                    partnerName,
                    '3',
                    'mail',
                  )
                }
              >
                {partner.email}
              </ButtonAlignedLeft>
            )}
          </Layout>
        </>
      )}
    </AddressContainer>
  );
};
