/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import { DayOpeningHours, DepartmentOpeningHours, PbcDataPartner } from './PartnerData';
import { SocialMediaConfig } from './socialMedia/socialMediaBar';

interface JsonLdProps {
  // eslint-disable-next-line
  readonly data: PbcDataPartner;
  jsonLdType: string;
  socialMedia: SocialMediaConfig;
}
// eslint-disable-next-line
export const JsonLd: React.FunctionComponent<JsonLdProps> = ({ data, jsonLdType, socialMedia }) => {
  const addressParts = data?.address?.[1]?.split(' ');
  // eslint-disable-next-line
  const openingHoursDepartments = data.openingHours?.departments?.map(
    (department: DepartmentOpeningHours) => ({
      name: `${department?.departmentName}`,
      image: `${data?.additionalData?.locationImage}`,
      // eslint-disable-next-line
      openingHoursSpecification: department?.openingHours?.map((item: DayOpeningHours) => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [`http://schema.org/${item?.id?.charAt(0)?.toUpperCase() + item?.id?.slice(1)}`],
        opens: item.timeRanges && `${item.timeRanges.length > 0 && item.timeRanges[0].openTime}`,
        closes:
          item?.timeRanges && `${item?.timeRanges.length > 0 && item.timeRanges[0].closeTime}`,
      })),
      '@type': `${jsonLdType}`,
    }),
  );

  const socialMediaLinks = socialMedia?.map((media: any) => media[`${Object.keys(media)[0]}Url`]);

  const jsonlddata = {
    '@context': 'https://schema.org',
    '@type': `${jsonLdType}`,
    '@id': `${data.url}`,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'DE',
      addressLocality: `${addressParts?.[1]}`,
      postalCode: `${addressParts?.[0]}`,
      streetAddress: `${data.address?.[0]}`,
    },
    brand: 'Audi',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: `${data?.phone}`,
      email: `${data?.email}`,
      contactType: 'Customer Service',
    },
    department: openingHoursDepartments,
    description: '',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: data?.lat,
      longitude: data?.long,
    },
    image: `${data?.additionalData?.locationImage}`,
    logo: `${data?.additionalData?.locationImage}`,
    name: `${data?.additionalData?.displayName}`,
    openingHoursSpecification: data?.openingHours?.departments?.[0]?.openingHours?.map(
      (item: DayOpeningHours) => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [`http://schema.org/${item.id.charAt(0).toUpperCase() + item.id.slice(1)}`],
        opens: item.timeRanges && `${item.timeRanges.length > 0 && item.timeRanges[0].openTime}`,
        closes: item.timeRanges && `${item.timeRanges.length > 0 && item.timeRanges[0].closeTime}`,
      }),
    ),
    sameAs: socialMediaLinks,
    telephone: `${data?.phone}`,
    url: `${data?.url}`,
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __html: JSON.stringify(jsonlddata),
      }}
    />
  );
};
