import { TextLink } from '@oneaudi/unified-web-components';
import styled from 'styled-components';
import { constants } from '../../../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  smallMobile: `(max-width:${constants.displaySizes.smallMobile})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width:${constants.displaySizes.tablet})`,
};

// export const StyleSocialLink = styled.div`
//   cursor: pointer !important;
//   a {
//     color: black;
//   }
//   a:hover{
//     color: #ccc;
//   }
// `;
export const StyleSocialLinkLayout = styled.div`
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;

  @media ${device.smallMobile} {
    max-width: 288px;
  }
  @media ${device.mobile} {
    max-width: 319px;
  }
  @media ${device.tablet} {
    max-width: 348px;
  }
  @media ${device.desktop} {
    max-width: 348px;
  }
  @media ${device.smallDesktop} {
    max-width: 348px;
  }
  @media ${device.largeDesktop} {
    max-width: 348px;
  }
  //override global css of unwanted forward arrow
`;

export const SocialButton = styled(TextLink)`
  width: 48px;
  margin: 0;
  padding: 0;

  div > span {
    height: 48px;
  }

  div > svg {
    display: none;
  }
`;
