/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { TrackingDataV2 } from '@oneaudi/audi-tracking-service';
import FeatureServiceContext from './FeatureServiceContext';
import packageInfo from '../../package.json';
import { DepartmentOpeningHours } from './components/PartnerData';
import { CtaBarButtonConfig } from './components/CtaBar';
import { layoutStyleStrings } from '../constants';

const { selectedInfoNarrow, selectedInfoLong, dealerAndAddress } = layoutStyleStrings;

interface ITrackingManager {
  pageLoad: (variant: string, partnerID: string) => void;
  click: (
    action: string,
    targetURL: string,
    elementName: string,
    label: string,
    headline: string,
    pos: string,
    elementValue: string,
  ) => void;
  impression(
    variant: string,
    headline: string,
    partnerDepartments?: DepartmentOpeningHours[],
    ctas?: CtaBarButtonConfig,
  ): void;
}

const { version: appVersion } = packageInfo;

const createAdditionalPageLoadData = (appVariant: string, partnerID: string) => ({
  componentUpdate: {
    componentInfo: {
      implementer: 2,
      version: appVersion,
      variant: appVariant,
    },

    dealer: {
      dealerInfo: {
        dealerID: partnerID,
      },
    },
  },
});

const createEventPayload = (
  action: string,
  name: string,
  attributes: Record<string, unknown>,
  additionalTrackingData?: Record<string, unknown>,
): TrackingDataV2 => {
  const defaultAttributes = {
    componentName: 'fa-partner-business-card',
  };

  return {
    event: {
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
    },
    ...additionalTrackingData,
  };
};

const getRelatedElements = (
  elementName: string,
  relatedElements: string[] = [],
): Array<Record<string, unknown>> =>
  relatedElements.map((element) => ({
    elementName,
    elementValue: element,
  }));
const getRelatedElementsForImpressions = (
  variant: string,
  departments: DepartmentOpeningHours[],
  ctas: CtaBarButtonConfig,
) => {
  const img = document?.getElementsByTagName('img')[0]?.src;
  const relEl: Array<Record<string, unknown>> = [];
  if (variant === selectedInfoLong || variant === selectedInfoNarrow) {
    relEl.push(
      ...getRelatedElements('cta', [...ctas.map((button) => button.label)]),
      ...getRelatedElements('link', ['phone', 'location', 'mail', 'contact data download']),
      ...getRelatedElements('image', img ? ['pws link'] : []),

      ...getRelatedElements('headline', ['pws link']),
    );
  }

  if (variant === selectedInfoNarrow) {
    relEl.push(
      ...getRelatedElements('accordion', [
        ...departments.map((department) => department.departmentName),
      ]),
    );
  }

  if (variant === dealerAndAddress) {
    relEl.push({
      elementName: 'link',
      elementValue: 'location',
    });
  }
  return relEl;
};

export function useTrackingManager(): ITrackingManager {
  const { trackingService, logger } = React.useContext(FeatureServiceContext);

  return React.useMemo((): ITrackingManager => {
    function track(...args: Parameters<typeof createEventPayload>): void {
      if (typeof window !== 'undefined' && trackingService) {
        const payload = createEventPayload(...args);
        // for testing
        console.log('payload', payload);
        trackingService.track(payload);
      }
    }

    return {
      pageLoad(variant, partnerID) {
        track(
          'feature_app_ready',
          'partner business card - feature app ready',
          {
            label: '',
            targetURL: '',
            clickID: '',
            elementName: '',
            value: '',
            pos: '',
          },
          createAdditionalPageLoadData(variant, partnerID),
        );
      },

      click(action, targetURL, elementName, label, headline, pos, elementValue) {
        const name = elementName === 'link' ? 'contact links' : elementName;
        track(action, `partner business card - click on ${name}`, {
          label,
          elementName,
          targetURL,
          clickID: '',
          value: headline,
          pos,
          relatedElements: [{ elementName, elementValue }],
        });
      },

      impression(
        variant,
        headline,
        departments: DepartmentOpeningHours[],
        ctas: CtaBarButtonConfig,
      ) {
        track('impression', 'partner business card - impression', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: headline,
          pos: '',
          relatedElements: getRelatedElementsForImpressions(variant, departments || [], ctas || []),
        });
      },
    };
  }, [logger, trackingService]);
}
