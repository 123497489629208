import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Headline, Text, TextLink } from '@oneaudi/unified-web-components';
import styled from 'styled-components';
import { HeadlineVariant } from '@oneaudi/unified-web-components/dist/cjs/components/headline';
import { messages } from '../../../i18n/messages';
import { isTranslationLoaded } from '../../../lib/isTranslationLoaded';
import { useMedia } from '../../../lib/hooks';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { ImprintModal } from '../ImprintModal';
import { ButtonAlignedLeft } from '../PartnerBusinessCardAddress';
import { PartnerContext } from '../../PartnerProvider';
import { useTrackingManager } from '../../useTrackingManager';
import { VtpDealer } from '../PartnerBusinessCard';
import { createDirectionsLink } from '../PartnerLinks';

interface Props {
  vtpData: {
    imprint?: string;
    vtpDealerResultsURL?: string;
    registrationNumber?: string;
    vtpDealer: VtpDealer;
    locale: string;
  };
  partnerName: string;
  pbcRef: React.RefObject<HTMLDialogElement>;
  isGoogleMapLink?: boolean;
  partnerAddress: React.JSX.Element[];
}

const GoogleButton = styled(TextLink)`
  text-decoration: none;
`;

const CustomButton = styled(ButtonAlignedLeft)`
  margin: 0 0 16px 0;
`;

const BevAgencyDealerContainer = styled.div`
  display: grid;
  .bev-agency-label {
    margin: 0 0 16px 0;
  }
`;

export const BevAgencyDealer: React.FC<Props> = ({
  vtpData,
  partnerName,
  pbcRef,
  isGoogleMapLink,
  partnerAddress,
}): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const trackingManager = useTrackingManager();
  const isViewportXS = useMedia(`only screen and (max-width: 375px)`);
  const { vtpDealer, locale } = vtpData;
  const { name, street, zipCode, city } = vtpDealer;
  const dealerAddress = `${street}, ${zipCode} ${city}`;
  const dealerDirectionUrl = createDirectionsLink({ ...vtpDealer, locale, address: dealerAddress });

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  const nameVariant = (() => {
    if (showTitle) return { type: 'headline', order: '4', weight: 'normal' };
    return { type: 'headline', order: isViewportXS ? '3' : '2', weight: 'bold' };
  })() as HeadlineVariant;

  const nameAs = (() => {
    if (showTitle) return 'div';
    return isViewportXS ? 'h3' : 'h2';
  })();

  return (
    <BevAgencyDealerContainer>
      <Headline
        tag={nameAs}
        variant={nameVariant}
        data-testid="bev-agency-label"
        className="bev-agency-label"
      >
        {name || partner.officialName || partner.nonOfficialName}
      </Headline>
      {vtpData?.registrationNumber && (
        <Text
          variant={{ type: 'copy', order: '2', style: 'normal' }}
          margin={[0, 0, 'var(--spacing-relative-lg)', 0]}
        >
          {useI18n(messages.registrationNumber)} {vtpData?.registrationNumber}
        </Text>
      )}
      {isGoogleMapLink && (
        <GoogleButton
          variant="standalone"
          icon="geofence"
          iconPosition="leading"
          size="md"
          margin={['var(--spacing-relative-sm)', 0, 'var(--spacing-relative-lg)', 0]}
          href={dealerDirectionUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackingManager.click(
              'exit_link',
              dealerDirectionUrl,
              'link',
              dealerAddress,
              name || partnerName,
              '1',
              'location',
            )
          }
          data-testid="directions"
        >
          {dealerAddress || partnerAddress}
        </GoogleButton>
      )}

      {vtpData?.imprint && (
        <>
          <CustomButton
            variant="standalone"
            icon="address-book"
            iconPosition="leading"
            size="md"
            onClick={() => {
              openCloseDialog(pbcRef.current as HTMLDialogElement);
            }}
            data-test-id="imprint-cta"
          >
            {useI18n(messages.imprintMessage)}
          </CustomButton>
          <ImprintModal imprintRef={pbcRef} imprintData={vtpData.imprint} />
        </>
      )}
    </BevAgencyDealerContainer>
  );
};
